import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import xt from '../components/xt'
import byr from '../components/byr'
import byrpage from '../components/byrpage'
import ewa from '../components/ewa'
import ewapage from '../components/ewapage'
import xdf from '../components/xdf'
import xdfpage from '../components/xdfpage'
import xtpage from '../components/xtpage'
import acpage from '../components/acpage'
import repage from '../components/repage'
import ipage from '../components/ipage'
import page1 from '../components/page1'
import litepage from '../components/litepage'
import standardpage from '../components/standardpage'
import propage from '../components/propage'
import propageCopy from '../components/propage copy copy'
import page2 from '../components/page2'
import page3 from '../components/page3'
import repages from '../components/repages'
import repagesTail from '../components/repagesTail'
import acpages from '../components/acpages'
import acpagesTail from '../components/acpagesTail'
import ipagesTail from '../components/ipagesTail'
import pdf from '../components/pdf'
import pdf2 from '../components/pdf2'
import pdf3 from '../components/pdf3'
import litelist from '../components/litelist'
import standardlist from '../components/standardlist'
import pro from '../components/pro'
import lite from '../components/lite'
import standard from '../components/standard'
import liteMobile from '../components/single/liteMobile'
import standardMobile from '../components/single/standardMobile'
import proMobile from '../components/single/pro/proMobile'
import singleView from '../components/single/singleView'
import singlelite from '../components/single/lite'
import singlexdf from '../components/single/xdf'
import singleewa from '../components/single/ewa'
import singleac from '../components/single/ac'
import singlestandard from '../components/single/standard'
import researchMobile from '../components/single/research'
import rtMobile from '../components/single/rt'
Vue.use(VueRouter)

const routes = [
  {
    path: '/single',
    name: 'single',
    component: singleView,
    children: [
      {
        path: 'lite/*',
        name: 'liteMobile',
        component: liteMobile
      },
      {
        path: 'standard/*',
        name: 'standardMobile',
        component: standardMobile
      },
      {
        path: 'pro/*',
        name: 'proMobile',
        component: proMobile
      },
      {
        path: 'proHigh/*',
        name: 'proHighMobile',
        component: proMobile
      },
      {
        path: 'xdf/*',
        name: 'singlexdf',
        component: singlexdf
      },
      {
        path: 'ewa/*',
        name: 'singleewa',
        component: singleewa
      },
      {
        path: 'ac/*',
        name: 'singleac',
        component: singleac
      },
      {
        path: 'research/*',
        name: 'researchMobile',
        component: researchMobile
      },
      {
        path: 'rt/*',
        name: 'rtMobile',
        component: rtMobile
      },
      {
        path: 'research',
        name: 'singlelite',
        component: singlelite
      },
      {
        path: 'thesis',
        name: 'singlestandard',
        component: singlestandard
      }
    ]
  },
  {
    path: '/acpage/*',
    name: 'acpage',
    component: acpage
  },
  {
    path: '/xdf/*',
    name: 'xdf',
    component: xdf
  },
  {
    path: '/byr/*',
    name: 'byr',
    component: byr
  },
  {
    path: '/ewa/*',
    name: 'ewa',
    component: ewa
  },
  {
    path: '/xt/*',
    name: 'xt',
    component: xt
  },
  {
    path: '/repage/*',
    name: 'repage',
    component: repage
  },
  {
    path: '/lite/*',
    name: 'lite',
    component: lite
  },
  {
    path: '/standard/*',
    name: 'standard',
    component: standard
  },
  {
    path: '/ipage/*',
    name: 'ipage',
    component: ipage
  },
  {
    path: '/pro/*',
    name: 'pro',
    component: pro
  },
  {
    path: '/page1',
    name: 'page1',
    component: page1
  },
  {
    path: '/litepage',
    name: 'litepage',
    component: litepage
  },
  {
    path: '/standardpage',
    name: 'standardpage',
    component: standardpage
  },
  {
    path: '/propage',
    name: 'propage',
    component: propage
  },
  {
    path: '/propageCopy',
    name: 'propageCopy',
    component: propageCopy
  },
  {
    path: '/litelist',
    name: 'litelist',
    component: litelist
  },
  {
    path: '/standardlist',
    name: 'standardlist',
    component: standardlist
  },
  {
    path: '/page2',
    name: 'page2',
    component: page2
  },
  {
    path: '/byrpage',
    name: 'byrpage',
    component: byrpage
  },
  {
    path: '/ewapage',
    name: 'ewapage',
    component: ewapage
  },
  {
    path: '/xdfpage',
    name: 'xdfpage',
    component: xdfpage
  },
  {
    path: '/xtpage',
    name: 'xtpage',
    component: xtpage
  },
  {
    path: '/page3',
    name: 'page3',
    component: page3
  },
  {
    path: '/repages',
    name: 'repages',
    component: repages
  },
  {
    path: '/repages/*',
    name: 'repagesTail',
    component: repagesTail
  },
  {
    path: '/acpages',
    name: 'acpages',
    component: acpages
  },
  {
    path: '/acpages/*',
    name: 'acpagesTail',
    component: acpagesTail
  },
  {
    path: '/ipages',
    name: 'ipagesTail',
    component: ipagesTail
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: pdf
  },
  {
    path: '/pdf2',
    name: 'pdf2',
    component: pdf2
  },
  {
    path: '/pdf3',
    name: 'pdf3',
    component: pdf3
  }
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
var router = new VueRouter({
  mode: 'history',
  routes
})
export default router
